/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllCity = params => {
  return instance.instanceToken
    .get("/city/all", {params})
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const SetCityVerify = payload => {
  return instance.instanceToken
    .post("/city/cityVerify", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const SetDelete = payload => {
  return instance.instanceToken
    .get("city/deleteCity/" + payload._id)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const setShow = payload => {
  return instance.instanceToken
    .get("city/isShow/" + payload._id)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const setWTTCCertificate = payload => {
  return instance.instanceToken
    .get("city/isWTTCCertificate/" + payload._id)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetCityAdminImage = payload => {
  return instance.instanceToken
    .post("/city/addAdminImage", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetTempAndRainFallInOct = payload => {
  return instance.instanceToken
    .post("/city/climate/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const GetCityProfileById = payload => {
  return instance.instance
    .get(`/city/getByid/${payload.googleId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const SetTopic = payload => {
  return instance.instanceToken
    .post(`/city/topics`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const SetGdsScore = async ({sustainabiltyCommitment, gdsScore, gdsProfile, cityId}) => {
  return instance.instanceToken
    .post(`/city/sustainabilty`, {
      sustainabiltyCommitment,
      gdsScore,
      gdsProfile,
      cityId
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
const SetSustainabilityInitiatives = (payload) => {
  return instance.instanceToken
    .post(`/city/sustainabilityInitiativesAdmin`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
const UploadSustainabilityCertificate = (payload) => {
  return instance.instanceFileUpload
    .post(`/supportDocuments/upload_sustainability_certificate`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
const UploadResearch = (payload) => {
  return instance.instanceFileUpload
    .post(`/admin/upload-quarterly-research`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const DeleteSupportDoc= (payload) => {
  return instance.instanceToken
    .delete("/supportDocuments/delete", {
      data: payload
    })
};

const DeleteSustainabilityCertificate= (payload) => {
  return instance.instanceToken
    .delete("/supportDocuments/delete_sustainability_certificate", {
      data: payload
    })
};

export default {
  getAllCity: GetAllCity,
  SetCityVerify: SetCityVerify,
  SetCityAdminImage: SetCityAdminImage,
  setDelete: SetDelete,
  setTopic: SetTopic,
  SetShow: setShow,
  SetWTTCCertificate: setWTTCCertificate,
  SetTempAndRainFallInOct: SetTempAndRainFallInOct,
  GetCityProfileById: GetCityProfileById,
  SetGdsScore,
  setSustainabilityInitiatives: SetSustainabilityInitiatives,
  uploadSustainabilityCertificate: UploadSustainabilityCertificate,
  deleteSustainabilityCertificate: DeleteSustainabilityCertificate,
  DeleteSupportDoc: DeleteSupportDoc,
  UploadResearch:UploadResearch
};
