/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllDirContacts = payload => {
  return instance.instanceToken
    .get("directContact/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const ApproveDirectContact = payload => {
  return instance.instanceToken
    .post(`/directContact/update`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
const UpdateAccess = (profile, status) => {
  return instance.instanceToken
    .get(`/directContact/toggleAccess/${profile}/${status}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
const UpdateOpenTenderAccess = (profile, status) => {
  return instance.instanceToken
    .get(`/openTendersSubscription/toggleAccess/${profile}/${status}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
export default {
  getAllDirContacts: GetAllDirContacts,
  approveDirectContact: ApproveDirectContact,
  updateAccess: UpdateAccess,
  updateOpenTenderAccess: UpdateOpenTenderAccess,
};
